import { useAtom } from "jotai";
import { categoryAtom } from "../state/categoryAtom";

/**
 * filter to only json objects
 * group by parent
 * sort by parent
 * sort by name
 * flatten to options that look like 
 * {
      count: 4
      data: null
      exhaustive: true
      isRefined: false
      label: "Board Rack Cover"
      value: "Board Rack Cover"
    }
 */

const createCategoryOption = (value, label, count) => {
  return {
    count: count,
    data: null,
    exhaustive: true,
    isRefined: false,
    label: label,
    value: value,
  };
};

export const useTransformCategories = () => {
  const [cat, setCat] = useAtom(categoryAtom);
  const transformCategories = (items) => {
    const originalItemsMap = new Map();
    const itemsMap = new Map();

    //Filter Items
    const filteredItems = items.reduce((prev, current) => {
      originalItemsMap.set(current.value, current);
      try {
        const obj = JSON.parse(current.value);
        return [...prev, obj];
      } catch (e) {
        return prev;
      }
    }, []);

    //Group by parent
    filteredItems.forEach(({ name, parent }) => {
      if (itemsMap.has(parent)) {
        itemsMap.set(parent, [...itemsMap.get(parent), name]);
      } else {
        itemsMap.set(parent, [name]);
      }
    });

    //Reconstruct formatted options array
    const options = [];
    for (let [parent, children] of itemsMap) {
      options.push(
        createCategoryOption(
          parent,
          parent,
          originalItemsMap.get(parent)?.count ?? 0
        )
      );
      children.forEach((child) => {
        options.push(
          createCategoryOption(
            child,
            ` - ${child}`,
            originalItemsMap.get(child)?.count ?? 0
          )
        );
      });
    }

    //Save categorymap to store
    if (!cat && filteredItems.length > 0) {
      setCat(filteredItems);
    }

    return options;
  };
  return transformCategories;
};

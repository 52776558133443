/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import { useMenu } from "react-instantsearch-hooks-web";
import { useAtom } from "jotai";
import { otherAtom } from "../state/otherAtom";
import { useBoatQuery } from "../utils/useBoatQuery";

export const SelectMenu = ({ defaultValue, showOther, filterBy, ...props }) => {
  const firstLoad = useRef(true);
  const [otherState, setOtherState] = useAtom(otherAtom);
  const [state, setState] = useState(defaultValue ?? "");
  const [otherField, setOtherField] = useState("");
  const { filterByAttribute } = useBoatQuery(props.attribute);
  const { items, refine } = useMenu(props);

  const filteredItems = useMemo(() => {
    if (!filterBy) return items;
    return filterByAttribute(filterBy, items);
  }, [props.attribute, items, filterByAttribute, filterBy]);

  const handleChange = (value) => {
    setState(value);
    refine(value === "other" ? " " : value ?? " ");
  };

  useEffect(() => {
    if (firstLoad.current && defaultValue) {
      setTimeout(() => {
        refine(defaultValue);
      }, [400]);
    }
    firstLoad.current = false;
  }, [firstLoad, defaultValue]);

  //Sync other fields
  useEffect(() => {
    if (state === "other") {
      setOtherState({
        ...otherState,
        [props.attribute]: otherField === "" ? "other" : otherField,
      });
    } else {
      setOtherState({
        ...otherState,
        [props.attribute]: null,
      });
    }
  }, [state, otherField, props.attribute]);

  if (defaultValue) return null;

  return (
    <div className={`flex  w-full relative sm:block`}>
      {state !== "other" && (
        <label className="anton min-w-[90px]" htmlFor={props.attribute}>
          {props?.label ?? props.attribute}
        </label>
      )}
      <select
        id={props.attribute}
        style={{ backgroundPositionY: 14 }}
        className={`w-full rounded-sm h-10 shadow-inner ${
          state === "other"
            ? "bg-[#dd2c2c] text-white p-0 h-8 border-none shadow-none anton"
            : "px-2 py-1.5"
        }`}
        value={state}
        onChange={(e) => handleChange(e.target.value)}
      >
        <option value="">All</option>
        {(filteredItems ?? []).map((item) => (
          <option key={item.value} value={item.value}>
            {item.label} ({item.count})
          </option>
        ))}
        {showOther && <option value="other">Other {props.attribute}</option>}{" "}
      </select>
      {state === "other" && (
        <input
          autoFocus
          className="w-full h-10 px-2 py-2 shadow-inner border-none rounded-sm text-gray-700"
          placeholder="Other..."
          value={otherField}
          onChange={(e) => setOtherField(e.target.value)}
        />
      )}
    </div>
  );
};

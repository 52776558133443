import { SearchButton } from "./components/SearchButton";
import { SelectMenu } from "./components/SelectMenu";
import { useTransformCategories } from "./hooks/useTransformCategories";

const defaultPageConfig = {
  searchLabel: "Search Products",
  category: "",
};
const pageConfigs = {
  "/cinch-covers/": {
    searchLabel: "Search Cinch Covers",
    category: "Cinch Covers",
    parent: "Boat Covers",
  },
  "/tower-down-ratchet-covers/": {
    searchLabel: "Search Covers",
    category: "Tower Down Ratchet Covers",
    parent: "Boat Covers",
  },
  "/storage-covers/": {
    searchLabel: "Search Covers",
    category: "Double Up Storage Covers",
    parent: "Boat Covers",
  },
  "/shade-sail/": {
    searchLabel: "Search Shade Sails",
    category: "Shade Sails",
    parent: "Shade",
  },
  "/folding-canopy-top/": {
    searchLabel: "Search Folding Canopy Tops",
    category: "Folding Canopy Tops",
    parent: "Shade",
  },
  "/bimini-enclosure/": {
    searchLabel: "Search Bimini Tops",
    category: "Bimini Tops",
    parent: "Shade",
  },
  "/swim-platform-covers/": {
    searchLabel: "Search Swim Platform Covers",
    category: "Swim Platform Covers",
    parent: "Accessory Covers",
  },
  "/speaker-covers/": {
    searchLabel: "Search Speaker Covers",
    category: "Speaker Covers",
    parent: "Accessory Covers",
  },
  "/board-rack-covers/": {
    searchLabel: "Search Board Rack Covers",
    category: "Board Rack Covers",
    parent: "Accessory Covers",
  },
  "/pole-guide-covers/": {
    searchLabel: "Search Pole Guide Covers",
    category: "Pole Guide Covers",
    parent: "Accessory Covers",
  },
};

function App() {
  let pathName = window.location.pathname;
  if (pathName.charAt(pathName.length - 1) !== "/") {
    pathName += "/";
  }
  const pageConfig = pageConfigs?.[pathName] ?? defaultPageConfig;
  const categoryHidden = Boolean(pageConfig?.category);
  const transformCategories = useTransformCategories();
  return (
    <div
      className={`p-0 grid sm:grid-cols-2 md:grid-cols-3  gap-6 text-white items-end ${
        categoryHidden ? "lg:grid-cols-6" : "lg:grid-cols-7"
      }`}
    >
      <div className="anton text-xl sm:col-span-2  md:col-span-3 text-center lg:col-span-1 lg:text-right">
        {pageConfig?.searchLabel}
      </div>

      <SelectMenu
        label="Category"
        attribute="categoryArray"
        limit={50}
        facetOrdering={false}
        transformItems={transformCategories}
        defaultValue={pageConfig?.category}
      />

      <SelectMenu
        attribute="Year"
        limit={50}
        filterBy="year"
        transformItems={(items) =>
          items.sort((a, b) => Number(b.value) - Number(a.value))
        }
        showOther
      />
      <SelectMenu attribute="Make" limit={50} facetOrdering={false} showOther />
      <SelectMenu
        attribute="Model"
        limit={50}
        facetOrdering={false}
        showOther
        filterBy="Model"
      />
      <SelectMenu
        attribute="Tower"
        limit={50}
        facetOrdering={false}
        showOther
        filterBy="Tower"
      />
      <SearchButton pageConfig={pageConfig} categoryHidden={categoryHidden} />
    </div>
  );
}

export default App;

const sleep = async (time) =>
  new Promise((resolve) => {
    setTimeout(resolve, time);
  });

export const getBoats = async (filters) => {
  //prevent overfetching
  if (window.currentlyFetching === filters) {
    await sleep(200);
  }
  window.currentlyFetching = filters;

  try {
    if (!filters) return null;

    const storageKey = `sewlong-boat-${filters}`;
    const cached = sessionStorage.getItem(storageKey);
    if (cached) return JSON.parse(cached);

    const res = await fetch(
      `https://10400KT4QR.algolia.net/1/indexes/boats/query`,
      {
        method: "POST",
        headers: {
          "X-Algolia-Application-Id": "10400KT4QR",
          "X-Algolia-API-Key": "b31b8750f6e12ab7a051e9aec2336831",
        },
        body: JSON.stringify({ hitsPerPage: 120, filters }),
      }
    );
    if (!res.ok) return [];

    const data = await res.json();

    //If we have more than one page, don't bother filtering further
    if (data.nbPages > 1) {
      sessionStorage.setItem(storageKey, JSON.stringify(null));
      return null;
    }
    const hits = data.hits;
    const boats = hits.map(({ Year, Make, Model, Tower }) => ({
      year: Year,
      Make,
      Model,
      Tower,
    }));

    //Cache results to sessionstorage
    sessionStorage.setItem(storageKey, JSON.stringify(boats));
    return boats;
  } catch (e) {
    return [];
  }
};

import { useCurrentRefinements, useHits } from "react-instantsearch-hooks";
import { categoryAtom } from "../state/categoryAtom";
import { useAtomValue } from "jotai";
import { otherAtom } from "../state/otherAtom";
/**
products[hierarchicalMenu][category.lvl0][0]=Accessory Covers
products[hierarchicalMenu][category.lvl0][1]=Swim Platform Cover
products[menu][Year]=2022
products[refinementList][Make][0]=Centurion
products[refinementList][Model][0]=Fi23
products[refinementList][Tower][0]=Battle/F-Series'
 */

const WP_FORM_ID = "wpf7463";
const WP_CATEGORY_ID = "11";
const WP_YEAR_ID = "2";
const WP_MAKE_ID = "3";
const WP_MODEL_ID = "4";
const WP_TOWER_ID = "10";

export const SearchButton = ({ categoryHidden, pageConfig }) => {
  const { results } = useHits();
  const { items } = useCurrentRefinements();
  const categoryMap = useAtomValue(categoryAtom);
  const otherState = useAtomValue(otherAtom);

  const hasOtherSelected = Object.values(otherState).some(Boolean);

  const getCategory = (value) =>
    categoryMap.find(
      (cat) =>
        cat?.name?.toLowerCase() === value?.toLowerCase() ||
        cat?.parent?.toLowerCase() === value?.toLowerCase()
    );

  const getWpFormQueryString = () => {
    console.log(items);
    console.log(otherState);
    const query = new URLSearchParams();
    const baseUrl = "/inquiry/";
    // if (pageConfig?.category) {
    //   query.set(`${WP_FORM_ID}_${WP_CATEGORY_ID}`, pageConfig?.category);
    // }

    items.forEach((item) => {
      if (item.attribute.toLowerCase() === "categoryarray") {
        const category = getCategory(item.refinements[0].value);
        query.set(`${WP_FORM_ID}_${WP_CATEGORY_ID}`, category?.name);
      }
      if (item.attribute.toLowerCase() === "year") {
        query.set(
          `${WP_FORM_ID}_${WP_YEAR_ID}`,
          otherState?.Year ?? item.refinements[0].value
        );
      }
      if (item.attribute.toLowerCase() === "make") {
        query.set(
          `${WP_FORM_ID}_${WP_MAKE_ID}`,
          otherState?.Make ?? item.refinements[0].value
        );
      }
      if (item.attribute.toLowerCase() === "model") {
        query.set(
          `${WP_FORM_ID}_${WP_MODEL_ID}`,
          otherState?.Model ?? item.refinements[0].value
        );
      }
      if (item.attribute.toLowerCase() === "tower") {
        query.set(
          `${WP_FORM_ID}_${WP_TOWER_ID}`,
          otherState?.Tower ?? item.refinements[0].value
        );
      }
    });
    return `${baseUrl}?${query.toString()}`;
  };

  const getQueryString = () => {
    const query = new URLSearchParams();
    const baseUrl = "/sewlong-search/";

    if (pageConfig?.category) {
      query.set(
        `products[hierarchicalMenu][category.lvl0][0]`,
        pageConfig?.parent
      );
      query.set(
        `products[hierarchicalMenu][category.lvl0][1]`,
        pageConfig?.category
      );
    }

    items.forEach((item) => {
      if (item.attribute.toLowerCase() === "year") {
        query.set(
          `${item.indexName}[menu][${item.attribute}]`,
          item.refinements[0].value
        );
      } else if (item.attribute.toLowerCase() === "categoryarray") {
        const category = getCategory(item.refinements[0].value);
        query.set(
          `${item.indexName}[hierarchicalMenu][category.lvl0][0]`,
          category?.parent
        );
        if (category?.parent !== item.refinements[0].value) {
          query.set(
            `${item.indexName}[hierarchicalMenu][category.lvl0][1]`,
            category?.name
          );
        }
      } else {
        query.set(
          `${item.indexName}[refinementList][${item.attribute}][0]`,
          item.refinements[0].value
        );
      }
    });

    return `${baseUrl}?${query.toString()}`;
  };

  const getLabel = () => {
    if (hasOtherSelected) {
      return "Make Inquiry";
    }
    if (items.length === 0) {
      return "View All";
    }
    return `View ${results.nbHits} Results`;
  };

  const handleClick = () => {
    if (hasOtherSelected) {
      const url = getWpFormQueryString();
      console.log(url);
      window.location.href = url;
    } else {
      const url = getQueryString();
      window.location.href = url;
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`w-full rounded-sm anton h-10 py-0 px-6 whitespace-nowrap bg-black ${
        categoryHidden && "sm:col-span-2 lg:col-span-1"
      }`}
    >
      {getLabel()}
    </button>
  );
};

import ReactDOM from "react-dom";
import App from "./App";
import algoliasearch from "algoliasearch/lite";
import { InstantSearch } from "react-instantsearch-hooks-web";
import "./index.css";

const searchClient = algoliasearch(
  "10400KT4QR",
  "b31b8750f6e12ab7a051e9aec2336831"
);

ReactDOM.render(
  <InstantSearch searchClient={searchClient} indexName="products">
    <App />
  </InstantSearch>,
  document.getElementById("react-product-search")
);

/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useCurrentRefinements } from "react-instantsearch-hooks-web";
import { getBoats } from "./getBoats";

const genQuery = (items, key) => {
  const refinements = items
    .find((item) => item.attribute === key)
    ?.refinements?.map((x) => x.value);

  if (!refinements) return null;
  return `(${refinements
    .map((value) => key + ":'" + value + "'")
    .join(" OR ")})`;
};

export const useBoatQuery = (skipProp) => {
  const [boats, setBoats] = useState(null);
  const { items } = useCurrentRefinements();
  const year = skipProp === "Year" ? null : genQuery(items, "Year");
  const make = skipProp === "Make" ? null : genQuery(items, "Make");
  const model = skipProp === "Model" ? null : genQuery(items, "Model");
  const tower = skipProp === "Tower" ? null : genQuery(items, "Tower");

  const query = [year, make, model, tower].filter(Boolean).join(" AND ");

  useEffect(() => {
    getBoats(query).then((data) => {
      setBoats(data);
    });
  }, [query]);

  const filterByAttribute = useCallback(
    (attribute, items) => {
      if (!boats || boats.length === 0) return items;
      return items.filter(({ value }) =>
        boats.some(
          (boat) =>
            boat?.[attribute]?.toLowerCase()?.trim() ===
            value?.toLowerCase()?.trim()
        )
      );
    },
    [boats]
  );

  return {
    filterByAttribute,
  };
};
